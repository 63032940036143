export default {
  data() {
    return {
      // 列表设置
      list: {
        url: '/canteen/account/clear',
        method: 'post',
        // 列表字段
        fields: this.getListFields(),
        action: false
      }
    };
  },
  methods: {
    // 表格的列定义
    getListFields() {
      return [
        { label: '姓名', prop: 'name', width: 120, headerAlign: 'center' },
        {
          label: '部门',
          prop: 'departmentName',
          headerAlign: 'center',
          showOverflowTooltip: true,

          formatter(row) {
            return row.departmentName?.split('、')[0];
          }
        },
        {
          label: '电话',
          prop: 'telephone',
          width: 160,
          headerAlign: 'center'
        },
        {
          label: '警号',
          prop: 'policeCard',
          width: 120,
          headerAlign: 'center'
        },
        {
          label: '消费分组',
          prop: 'consumerGroupName',
          width: 80,
          align: 'center'
        },
        {
          label: '补贴余额',
          prop: 'subsidyMoney',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.subsidyMoney}`;
          }
        },
        {
          label: '现金余额',
          prop: 'balance',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.balance}`;
          }
        },
        {
          label: '总余额',
          prop: 'totalBalance',
          width: 120,
          align: 'right',
          headerAlign: 'center',
          formatter(row) {
            return `￥${row.totalBalance}`;
          }
        },
        {
          label: '清零时间',
          prop: 'createTime',
          width: 260,
          align: 'center',
          headerAlign: 'center'
        }
      ];
    }
  }
};
