<template>
  <app-page :toolbar="toolbar" :list="list" @loaded="onLoaded"></app-page>
</template>

<script>
import mixins from './mixins';

export default {
  name: 'Clear',
  mixins
};
</script>
