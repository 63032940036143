import dayjs from 'dayjs';

export default {
  data() {
    return {
      // 工具栏设置
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        // 重置忽略的条件
        igrone: ['canteenType'],
        // 搜索条件
        model: {
          name: undefined,
          departmentId: undefined,
          policeCard: undefined,
          consumerGroupId: undefined,
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
          startDate: dayjs()
            .startOf('month')
            .format('YYYY-MM-DD'),
          endDate: dayjs()
            .endOf('month')
            .format('YYYY-MM-DD')
        },
        // 搜索条件字段
        fields: this.getToolbarFields()
      }
    };
  },
  methods: {
    onLoaded(){
      if (sessionStorage.getItem('canteenType')){
        if (Number(sessionStorage.getItem('canteenType')) === 1){
          this.toolbar.fields[4].options = [{ label: '一食堂', value: 1 }]
        }else if (Number(sessionStorage.getItem('canteenType')) === 2){
          this.toolbar.fields[4].options = [{ label: '二食堂', value: 2 }]
        }
      }
    },
    // 工作栏搜索条件字段定义
    getToolbarFields() {
      return [
        {
          label: '姓名',
          prop: 'name',
          placeholder: '请输入要搜索的姓名',
          clearable: true
        },
        {
          label: '部门',
          prop: 'departmentId',
          placeholder: '请选择要搜索的部门',
          type: 'cascader',
          clearable: true,
          collapseTags: true,
          filterable: true,
          props: {
            expandTrigger: 'hover',
            multiple: true,
            label: 'name',
            value: 'id'
          },
          options: [],
          url: '/department/getList',
          method: 'post'
        },
        {
          label: '警号',
          prop: 'policeCard',
          placeholder: '请输入要搜索警号',
          clearable: true
        },
        {
          label: '分组',
          prop: 'consumerGroupId',
          placeholder: '请选择要搜索分组',
          type: 'select',
          clearable: true,
          options: [],
          url: '/canteenConsumerGroup/dropDownList',
          mapper(item) {
            return {
              label: item.name,
              value: item.id
            };
          }
        },
        {
          label: '食堂',
          prop: 'canteenType',
          placeholder: '请选择要搜索食堂',
          type: 'select',
          // clearable: true,
          options: [
            { label: '全部', value: undefined },
            { label: '一食堂', value: 1 },
            { label: '二食堂', value: 2 }
          ]
        },
        {
          label: '查询日期',
          prop1: 'startDate',
          prop2: 'endDate',
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd',
          format: 'yyyy-MM-dd',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          pickerOptions: {
            firstDayOfWeek: 1
          }
        }
      ];
    }
  }
};
